<template>
<Nav/>
  <router-view/>

</template>

<script>

import {
  onMounted,
    computed
} from "vue";

import {
  useRoute
} from "vue-router";

import Nav from "./component/Nav.vue"



export default {
  components: {
    Nav
  },
  setup(){

    const route = useRoute();
    const currentRoutePath = computed(() => {
      return route.path
    })


    onMounted(() => {
    console.log(currentRoutePath.value)
      //console.log(router.path)
    })
  }



}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
