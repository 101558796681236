import {createWebHistory, createRouter} from "vue-router";

const routes = [
    {
        path: "/",
        name: "Home",
        component: () => import('../views/Home.vue')
    },
    {
        path: '/about',
        name: 'About',
        component: () => import('../views/About.vue')
    },
    {
        path: '/involve',
        name: 'Get Involved',
        component: () => import('../views/Involve.vue')
    },

/*
    {
        path: "/:catchAll(.*)",
        component: () => import(/!* webpackChunkName: "" *!/ '../views/NotFound.vue')
    },*/


];

const router = createRouter({
    history: createWebHistory(),
    routes,
});


export default router;