import { createStore } from "vuex"


const store = createStore({
    state:{
        enteredWeb: "",
        responses:[],

        questions: [
            {
                id: 0,
                questionType: [
                    {
                        option: "A",
                        feedback: "",
                        header:"This is about potat",
                        content: "Is chicken a direct result of potat?",
                        image:"images/picture_placeholder.jpg",
                        tip:"",
                        answer_a: "It's like",
                        answer_b: "BBQ"
                    },
                    {
                        option: "B",
                        feedback: "",
                        content: "",
                        header:"This is Question 1B",
                        image:"",
                        tip:"",
                        answer_a: "",
                        answer_b: ""
                    }
                ]

            },
            {
                id: 1,
                questionType: [
                    {
                        option: "A",
                        feedback: "You chose A",
                        header:"This is about hippies",
                        content: "How many hippies can you withstand",
                        image:"images/picture_placeholder.jpg",
                        tip:"",
                        answer_a: "Option A",
                        answer_b: "Option B"
                    },
                    {
                        option: "B",
                        feedback: "You chose B",
                        header:"This is about prinnie",
                        content: "Is prinnie a park or a person",
                        image:"images/picture_placeholder_inverted.jpg",
                        tip:"",
                        answer_a: "Neither",
                        answer_b: "Both"
                    }
                ]

            },
            {
                id: 2,
                questionType: [
                    {
                        option: "A",
                        feedback: "You chose A",
                        header:"This is about remberin",
                        content: "Rember the ttimes?",
                        image:"images/picture_placeholder.jpg",
                        tip:"",
                        answer_a: "Sunny lake",
                        answer_b: "Beachy mumph"
                    },
                    {
                        option: "B",
                        feedback: "You chose B",
                        header:"This is about dj",
                        content: "What's Jen's DJ name",
                        image:"images/picture_placeholder_inverted.jpg",
                        tip:"",
                        answer_a: "...",
                        answer_b: "Patrollin'"
                    }
                ]

            },


        ]
    },

    getters: {
        enteredWeb: (state) => state.enteredWeb,
        questions: (state) => state.questions,
        responses: (state) => state.responses,
    },

    mutations: {
        SET_entered(state, value) {
            state.enteredWeb = value
        },
        SAVE_response(state, value) {
            state.responses.push(value)
        },
    },

    actions: {
        setEntered(context, value) {
            context.commit("SET_entered", value)
        },
        saveResponse(context, value) {
            context.commit("SAVE_response", value)
        }
    },

})

export default store
