<template>

 <header>
   <div class="flex  flex-initial h-30 justify-center h-1/8">
    <img 
    src="/images/newlogo.png"
    class="h-32 md:shrink-0" alt="..." > 
   </div>
 </header>

  <nav class= "text-xl text-gray-700 font-sans p-4 z-10" >
  <div class=" bg-limish container flex flex-wrap justify-evenly items-center mx-auto h-20">
      <span className ="lg px-3 py-2 hover:bg-gray-300 transition ease-in-out delay-150">
      <router-link to="/" >Who We Are</router-link>
      </span>
      <span  className ="lg px-3 py-2 hover:bg-gray-300 transition ease-in-out delay-150">
      <router-link to="/about">Our Plan</router-link>
      </span>
      <span  className ="lg px-3 py-2 hover:bg-gray-300 transition ease-in-out delay-150">
      <router-link to="/involve" >Get Involved</router-link>
      </span>
  </div>
  </nav>
</template>

<script>

export default {
  setup() {
    function clickedIt(event){
          console.log(event)
          console.log(event.target)
    }

    return {
      clickedIt
    }
  },
}
</script>

<style scoped>

</style>